@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  background-color: #040b11;
  color: white;
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* Logo Image Mask */
.logoback-gradient-mask {
  -webkit-mask-image: linear-gradient(to bottom, #2ebd85 0%, #0053aa 100%);
  mask-image: linear-gradient(to bottom, #2ebd85 0%, #0053aa 100%);
}

.mask1 {
  /* -webkit-mask-image: linear-gradient(to bottom, #2ebd85 0%, #0053aa 100%);
  mask-image: linear-gradient(to bottom, #2ebd85 0%, #0053aa 100%); */
  background: linear-gradient(to bottom, #2ebd85 0%, #0053aa 100%);
}

/* Full Screen Class for Chart Full Screen */
.full-screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  /* height: 100%; */
  z-index: 999;
  padding: 3%;
  background-color: rgba(11, 22, 32, 1);
}

.full-screen-chart {
  position: relative;
  top: 5%;
  left: 5%;
  right: 0;
  bottom: 0;
  width: 90%;
  /* height: 90%; */
  z-index: 999;
}

/* Classes for Gradient Slider */
.apporoval-progress1 {
  background: linear-gradient(90deg, #1ca65e 0%, #f5a623 50.52%, #d0021b 100%);
  border-radius: 50px;
  height: 100%;
}

.apporoval-progress2 {
  background: linear-gradient(90deg, #d0021b 0%, #f5a623 50.52%, #1ca65e 100%);
  border-radius: 50px;
  height: 100%;
}

.apporoval-progress3 {
  background: linear-gradient(90deg,
      #1ca65e 16.15%,
      #f5a623 50%,
      #d0021b 90.1%);
  border-radius: 10px;
  height: 72px;
}

.vertical-line {
  position: absolute;
  z-index: 5;
  top: 0px;
  bottom: 0px;
  border-right: 3px solid white;
}

.stripes {
  background-image: repeating-linear-gradient(90deg,
      rgba(4, 11, 17, 0.5),
      rgba(4, 11, 17, 0.5) 90px,
      rgb(11, 22, 32) 0px,
      rgb(11, 22, 32) 160px);
  background-position: 10px;
}

/* ChartJs Loading Effect */
.loading-progress {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 300px;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top-color: #333;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s ease-in-out infinite;
}

.text {
  margin-top: 16px;
  font-size: 24px;
  font-weight: bold;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.netexpensesratio-progress {
  background: linear-gradient(90deg,
      #079325 29.05%,
      #bb9d13 46.08%,
      #c36816 63.11%,
      #d1041b 69.55%,
      #990313 96.17%);
  height: 10px;
}

.DOBDatePicker {
  .react-datepicker {
    width: 100%;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker__input-container input {
    width: 100%;
    height: 2.25rem;
    border-radius: 0.5rem;
    color: white;
    background: transparent;
    border: 1px rgb(107 114 128) solid;
    padding-left: 10px;
  }
}